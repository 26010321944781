#virtual-operator-view {
    margin: 7rem 2rem 0rem 4rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .title-section {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-family: var(--font-family-main, Inter);
            font-size: 48px;
            font-weight: 400;
            line-height: 64px;
        }
    }

    .virt-op-view-alerts {
        display: flex;
        justify-content: center;
        align-items: flex-start;

        gap: 8px;
        padding: 16px 24px;

        border-radius: 16px;
        background: var(--neutral-dark-dn-40, #262b31);

        > svg {
            margin: auto 0;
        }

        .virt-op-view-alert-body {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-grow: 1;

            gap: 10px;

            font-family: var(--font-family-main, Inter);
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
        }

        .virt-op-view-alert-body-title {
            flex-grow: 1;
            text-align: start;
        }

        .virt-op-view-alert-body-time {
            flex-grow: 1;
            text-align: end;
        }
    }

    .date-element {
        font-family: var(--font-family-main, Inter);
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
    }
}
